<template>
  <div class="order-container container_media">
    <p class="card-title">我的订单</p>
    <div class="detail-container">
      <div class="container-title">
        <p>订单详情</p>
        <div class="member-tips" @click="handleBack">
          <el-icon name="arrow-left" /> 返回
        </div>
      </div>

      <div class="order-level-container" v-loading="loading">
        <!-- 权益内容 -->
        <div class="order-info">
          <div class="info">
            <ul>
            <li>
              <div class="label-name">订单号：</div>
              <div class="label-info">{{orderInfo.order_no}}</div>
            </li>
            <li>
              <div class="label-name">下单时间：</div>
              <div class="label-info">{{formDateTime(orderInfo.created_at)}}</div>
            </li>
            <li>
              <div class="label-name">订单状态：</div>
              <div class="label-info">{{orderInfo.status | transitionValToLabel(RETAIL_PAY_STATUS)}}</div>
            </li>
            <li>
              <div class="label-name">支付时间：</div>
              <div class="label-info">{{orderInfo.status == 4 ? formDateTime(orderInfo.received_at) : '-'}}</div>
            </li>
            <li >
              <div class="label-name">支付方式：</div>
              <div class="label-info" v-if="orderInfo.status == 4">{{orderInfo.pay_type | transitionValToLabel(PAY_TYPE)}}</div>
              <div class="label-info" v-else>-</div>
            </li>
          </ul>
          <ul>
            <li>
              <div class="label-name">订单总额：</div>
              <div class="label-info">￥{{Number(orderInfo.pay_amount / 100)}}</div>
            </li>
            <li>
              <div class="label-name">优惠抵扣：</div>
              <div class="label-info">暂无优惠</div>
            </li>
            <li>
              <div class="label-name">实付金额：</div>
              <div class="label-info">{{orderInfo.status == 4 ? `￥${Number(orderInfo.paid_amount / 100)}` : '-'}}</div>
            </li>
          </ul>
          </div>
          <div  class="pay-btn">
            <el-button class="retail-pay" v-if="orderInfo.status == 0 && orderInfo.pay_type == 'PUBLIC_PAY'">支付确认中</el-button>
            <el-button v-else :loading="payLoading" class="retail-pay" :class="{'readd-btn': orderInfo.status == 4}" @click="handlePay">
              {{orderInfo.status | transitionValToLabel(RETAIL_PAY_BTN)}}
            </el-button>
          </div>
        </div>
        <div class="order-list">
          <el-table ref="multipleTable" :data="[orderInfo.extend]" class="order-table" cell-class-name="cart-table-cell-class">
            <el-table-column label="商品信息">
              <template slot-scope="{ row: { name, logo, slr_name} }">
                <div class="music-info">
                  <el-image class="music-img" :src="logo">
                    <div slot="error" class="image-slot">
                      <img src="@/assets/image/default-music-img.png" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <span class="music-name">{{ name }}</span>
                    <p>{{slr_name}}</p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="授权信息" align="left">
              <template slot-scope="{ row: { sk_id_arr} }">
                <div v-for="item in sk_id_arr" :key="item.sk_id">{{item.sk_name}}</div>
              </template>
            </el-table-column>
            <el-table-column label="价格" align="center">
              <template>
                <span>￥{{Number(orderInfo.pay_amount / 100)}}</span>
              </template>
            </el-table-column>
        </el-table>
        </div>
      </div>
    </div>
    <DialogForPay
      :isRetail="true"
      :dialogVisible="dialogVisiblemember"
      :payGoodInfo="payGoodInfo"
      :wxPayQrCode="wxPayQrCode"
      :aliPayQrCode="aliPayQrCode"
      @close="handlePayClose"
      @publicSubmit="handlePublicSubmit"
    ></DialogForPay>
  </div>
</template>

<script>
import { RETAIL_PAY_STATUS, RETAIL_PAY_BTN, PAY_TYPE } from "@/enum/common";

import { feachRetailOrderInfo } from '@/api/member'
import { formDateTime } from '@/utils/formDate.js';
import { payReloadTime } from '@/config'
import DialogForPay from '@/components/DialogForPay'
import { fetchMusicPayCode, fetchPayCodeResult, reAddMusicOrder, repeatMusicOrder } from '@/api/music'


export default {
  name: 'OrderDetail',

  data() {
    return {
      PAY_TYPE,
      retailTimer: null,
      dialogVisiblemember: false,
      wxPayQrCode: '',
      aliPayQrCode: '',
      payGoodInfo: {},
      payLoading: false,
      loading: false,
      RETAIL_PAY_STATUS,
      RETAIL_PAY_BTN,
      orderInfo: {}
    }
  },
  components: {
    DialogForPay
  },
  created() {
    // this.id = this.$route.params.id // 必要项
    this.getOrderDetail()
  },
  methods: {
    formDateTime,
    handleBack(){
      this.$router.go(-1);
    },
    async getOrderDetail(){
      this.loading = true
      try {
        const res = await feachRetailOrderInfo({order_id: this.$route.params.id})
        if (res.code === 0) {
          this.orderInfo = res.data
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    handlePay(){
      const {status, order_id, pay_amount, extend} = this.orderInfo
      this.retailOrderId = order_id
      this.payLoading = true
      this.payGoodInfo = {...this.orderInfo, price: Number(pay_amount / 100 )}
      switch (status) {
        // 待支付
        case 0:
          this.getMusicPayCode(order_id)
          break;
        // 已超时
        case 2:
          this.reAddOrder(order_id)
          break;
        // 已支付
        case 4:
          this.payLoading = false
          // this.repeatAddOrder(order_id)
          this.openAgain(extend.music_id)
          break;

        default:
          break;
      }
    },
    openAgain(id){
      let routeData = this.$router.resolve({
        path: `/music-detail/${id}`,
      })
      window.open(routeData.href, '_blank');
    },
    async reAddOrder(order_id){
      try {
        this.payLoading = true
        const res = await reAddMusicOrder({
          order_id
        })
        if (res.code === 0) {
          this.payGoodInfo = {...res.data, price: Number(res.data.pay_amount / 100 )}
          this.retailOrderId = res.data.order_id
          this.getMusicPayCode(res.data.order_id)
        }else {
          this.payLoading = false
        }
      } catch (error) {
        this.payLoading = false
        console.log(error)
      }
    },
    async repeatAddOrder(order_id){
      try {
        this.payLoading = true
        const res = await repeatMusicOrder({
          order_id
        })
        if (res.code === 0) {
          this.payGoodInfo = {...res.data, price: Number(res.data.pay_amount / 100 )}
          this.retailOrderId = res.data.order_id
          this.getMusicPayCode(res.data.order_id)
        }else {
          this.payLoading = false
        }
      } catch (error) {
        this.payLoading = false
        console.log(error)
      }
    },

    // 获取音乐零售订单
    getMusicPayCode(order_id) {
      //个人会员
      Promise.all([
        fetchMusicPayCode({order_id, pay_way:'WECHAT'}),
        fetchMusicPayCode({order_id, pay_way:'ALI'})
      ]).then((res) => {
        console.log(res);
        this.dialogVisiblemember = true
        this.payLoading = false
        this.retailOrderNO = res[0].data.order_no
        this.wxPayQrCode = res[0].data.pay_url
        this.aliPayQrCode = res[1].data.pay_url
        this.handleViewRetailOrderResult(this.retailOrderNO)
      })
    },
    // 零售订单支付结果查询
    async handleViewRetailOrderResult(order_no, name='retailTimer') {
      // 进入即刻清除定时器
      try {
        const { data } = await fetchPayCodeResult({
          order_no,
          share_token: window.localStorage.getItem('share_token')
            ? window.localStorage.getItem('share_token')
            : ''
        })
        // 判断订单的状态 -1表示订单状态不存在 0表示订单支付成功
        if (data.status == -1) {
          // 添加定时器
          this[name] = setTimeout(() => {
            this.handleViewRetailOrderResult(order_no)
          }, payReloadTime)
          return
        } else if (data.status == 0) {
          this.dialogVisiblemember = false
          this.wxPayQrCode = ''
          this.aliPayQrCode = ''
          this.handleClearCountDown(name)
          this.$router.push(`/music/order-result/${this.retailOrderId}`)
        }
        this.handleClearCountDown(name)
      } catch (e) {
        console.log(e)
        this.handleClearCountDown(name)
      }
    },
    handlePublicSubmit() {
      this.wxPayQrCode = ''
      this.aliPayQrCode = ''
      this.handlePayClose()
      this.getOrderDetail()
    },
    handleClearCountDown(name) {
      const timer = this[name]
      if (timer) {
        clearTimeout(timer)
        this[name] = null
      }
    },
    handlePayClose(){
      this.dialogVisiblemember = false
      this.handleClearCountDown('retailTimer')
    },

  }
}
</script>

<style lang="scss" scoped>
.order-container {
  padding: 56px 0;
  color: #333333;

  .card-title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
  }

  .detail-container {
    width: 100%;
    min-height: 500px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 40px;
    padding: 30px 50px;

    .container-title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 28px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .member-tips {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    cursor: pointer;
  }

  .auth-tips {
    font-size: 14px;
    //color: #E52A0D;
    padding-left: 44px;

    .el-icon-warning {
      margin-right: 5px;
      color: #ff8e14;
    }
  }

  .order-level-container {
    .order-info {
      display: flex;
      justify-content: space-between;
      .info{
        display: flex;
      }
      ul {
        padding-left: 38px;

        li {
          display: flex;
          flex-wrap: nowrap;
          margin-bottom: 20px;

          .label-name {
            min-width: 84px;
            white-space: nowrap;
            text-align: right;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            margin-right: 20px;
          }

          .label-info {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);

            .list-firm {
              margin-bottom: 11px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          .op-btn {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0061F3;
            margin-left: 12px;
            cursor: pointer;
          }
          .redText {
            color: $theme-color;
            font-size: 12px;
          }

          &.qrcode {
            .label-info {
              width: 168px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(51, 51, 51, 0.6);
              line-height: 20px;

              .qrcode-img {
                width: 120px;
                height: 120px;
                background: #d8d8d8;
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .pay-btn{
      align-self: flex-end;
      margin-bottom: 20px;
      .retail-pay {
        display: inline-block;
        width: 90px;
        height: 32px;
        line-height: 32px;
        background: #E52A0D;
        border-radius: 4px;
        text-align: center;
        color: #FFFFFF;
        font-weight: 400;
        border: none;
        padding: 0;
      }
      .readd-btn {
        background: #F5F6FA;
        color: rgba(0,0,0,0.85);
      }
    }
    .order-list {
      .music-info {
        display: flex;
        align-items: center;
        .name {
          margin-bottom: 4px;
        }
        p{
          font-weight: 400;
          color: rgba(0,0,0,0.5);
        }
      }

      /deep/ {
        .order-table {
          border: 1px solid #DDDDDD;
          border-radius: 12px;
          .el-table__header {
            color: #666666;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            .el-table__cell {
              background-color: #FAFAFA;
            }
          }
          .el-table__cell {
            padding-left: 40px;
          }
          img{
            width: 70px;
            height: 70px;
            margin-right: 20px;
          }
          .el-table__body .cell{
            color: #333333;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
